import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './components/home/home';
import About from './components/about/about';
import Contact from './components/contact/contact';
import Floors from './components/floors/floors';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Treatments from './components/treatments/treatments';


function App() {

  return (
    <div className="App">
      <Header />
      <Router>
        <Route exact path='/' component={Home} />
        <Route path='/vloeren' component={Floors} />
        <Route path='/behandelingen' component={Treatments} />
        <Route path='/over' component={About} />
        <Route path='/contact' component={Contact} />
      </Router>
      <Footer />
    </div>
  );
}

export default App;
